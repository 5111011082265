<template>
    <div class="search">
        <div class="search-toggle">
            <div
                v-if="!open"
                class="search-plus"
                @click="toggle()"
            />

            <div
                v-else
                class="search-minus"
                @click="toggle()"
            />
        </div>

        <transition name="search">
            <div
                v-if="open"
                class="search-input"
            >
                <input
                    ref="query"
                    v-model="query"
                    type="text"
                    :placeholder="$translate('search_box_placeholder', 'Sök efter kort')"
                    class="search-input__input"
                    @keydown.esc="toggle()"
                >

                <ul
                    v-if="query.length > 2 && open"
                    class="search-input__results"
                >
                    <li
                        v-for="(item, index) in filteredList"
                        :key="index"
                        @mouseup="toggle()"
                    >
                        <nuxt-link :to="$prismic.linkResolver(item)">
                            <PrismicImage
                                :img="item.data.card_image"
                                w="50"
                                h="32"
                            />
                            <span>{{ item.data.title }}</span>
                        </nuxt-link>
                    </li>
                </ul>
            </div>
        </transition>


        <transition name="fade">
            <div
                v-if="open"
                class="search-overlay"
                @click="toggle()"
                @keydown.esc="toggle()"
            />
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            query: '',
            filteredList: [],
            open: false
        };
    },
    watch: {
        open() {
            if (this.open) {
                let self = this;
                this.$nextTick(() => {
                    self.$refs.query.focus();
                });
            }
        },
        query() {
            this.filteredList = [];
            Object.keys(this.data).forEach(key => {
                let title = this.data[key].data.title;
                let titleFormatted = title.toLowerCase();
                if (titleFormatted.includes(this.query.toLowerCase())) {
                    this.filteredList.push(this.data[key]);
                }
            });
        }
    },
    methods: {
        toggle() {
            this.query = '';
            if (this.open) this.open = false;
            else this.open = true;
        }
    }
};
</script>
<style lang="scss" scoped>
    .search {
        @include flex;
        @include spacing(padding,2);
        margin-right: 10px;
        position: static;
        @include device(pad) {
            position: relative;
        }
        height: 100%;

        &-toggle {
            font-size: 24px;
            cursor: pointer;

            .search-plus {
                &:after {
                    content: "\f002";
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 400;
                    color: $pink;
                }
            }

            .search-minus {
                &:after {
                    content: "\f010";
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 400;
                    color: $red;
                }
            }
        }

        &-input {
            z-index: 99;
            @include spacing(padding,2);
            @include border-radius(10px,(bottom-left,bottom-right));
            position: absolute;
            border: 1px solid #e6e6e6;
            background: $white;
            top: 55px;
            right: 0;
            @include flex;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 35px -5px, rgba(0, 0, 0, 0.04) 0px 20px 20px -5px;

            @include device(mobile) {
                left: 0;
            }
            @include device(pad) {
                width: 350px;
                top: 100%;
            }

            &__input {
                right: 0;
                flex: 1;
                padding: 10px 15px;
                box-sizing: border-box;
                font-size: 16px;
            }

            &__results {
                width: 100%;
                list-style-type: none;
                max-height: 300px;
                overflow-y: scroll;

                li a {
                    @include flex(start);
                    @include spacing(padding,1);
                    @include border;
                    font-family: $fontText;
                    color: $font;
                    font-size: 14px;
                    margin-top: -1px;
                    background: #f2f2f2;

                    &:hover { color: $pink; }

                    span { flex: 1; }

                    img {
                        @include border-radius(3px);
                        margin-right: 20px;
                    }
                }
            }
        }

        &-overlay {
            z-index: 1;
            position: fixed;
            left: 0;
            top: 0;
            // background: rgba(0,0,0,.5);
            @include size(100%, 100%);
        }
    }

    .search-enter-active {
        transition: all .15s ease-in-out;
    }
    .search-leave-active {
        transition: all .15s ease-in-out;
    }
    .search-enter, .search-leave-to {
        transform: translateX(100px);
        opacity: 0;
    }
</style>
