<template>
    <div
        :class="align"
        class="banner-header"
    >
        <h1 class="banner-header__header">{{ header }}</h1>

        <div class="banner-header__hr" />

        <div
            class="banner-header__teaser rich-text"
            v-html="$prismic.asHtml(teaser)"
        />
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: false,
            default: String
        },
        teaser: {
            type: Array,
            required: false,
            default: () => {}
        },
        align: {
            type: String,
            required: false,
            default: 'center'
        }
    }
};
</script>

<style lang="scss" scoped>
    .banner-header {
        width: 100%;
        @include flex;

        &__header,
        &__teaser { width: 100%; }

        &__hr {
            height: 10px;
            background: $pink;
            width: 300px;
            max-width: calc(100% - 40px);
            @include spacing(margin, 6, (top,bottom));
        }
    }

    .banner-header.center {
        text-align: center;
        justify-content: center;
    }
    .banner-header.left { justify-content: flex-start; }
    .banner-header.right { justify-content: flex-end; }
</style>
