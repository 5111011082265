<template>
    <div
        v-if="show"
        class="compare-cards"
    >
        <div
            v-for="(cardId, index) in cardsToCompare"
            :key="`cmpbar${index}`"
            @click="remove(cardId)"
            class="compare-cards__image"
        >
            <PrismicImage
                :img="cards[cardId].data.card_image"
                w="75"
                h="auto"
            />
        </div>

        <SearchCards
            :placeholder="$translate('search_box_placeholder', 'Sök efter kort')"
            :link="false"
            @click="setCard"
        />

        <nuxt-link
            to="/compare"
            class="compare-cards__btn-compare btn btn-sm btn-pink"
            :class="{inactive: cardsToCompare.length < 2}"
        >
            {{ $translate('compare_cards') || 'Jämför kort' }}
            <span class="count">{{ count }}</span>
        </nuxt-link>

        <button
            type="button"
            class="btn compare-cards__btn-clear"
            @click="clear();"
        >
            <i class="fas fa-times red" />
        </button>
    </div>
</template>

<script>
import SearchCards from '@/components/compare-cards/SearchCards.vue';
export default {
    components: { SearchCards },
    data () {
        return {
            search: false,
            comparePage: false
        };
    },
    computed: {
        show: function() {
            return this.count > 0;
        },
        count: function() {
            return this.$store.state.cardsToCompare.length;
        },
        cards: function() {
            return this.$store.state.cards;
        },
        cardsToCompare: function() {
            return this.$store.state.cardsToCompare;
        }
    },
    watch: {
        search() {
            if (this.search) {
                document.body.style.overflow = 'hidden';
            }
            else {
                document.body.style.overflow = 'visible';
            }
        }
    },
    methods: {
        remove(cardId) {
            this.$store.commit('REMOVE_CARD_FROM_COMPARE', cardId);
        },
        clear: function() {
            this.$store.commit('CLEAR_CARDS_TO_COMPARE');
        },
        setCard(card) {
            this.$store.commit('ADD_CARD_TO_COMPARE', card.id);
        }
    }
};
</script>
<style lang="scss" scoped>

    .compare-cards {
        @include device(mobile) {
            @include flex(between);
        }
        
        @include flex(start);
        @include spacing(padding,2);
        position: fixed;
        box-shadow: $bxs;
        background: $white;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        transition: .3s ease-in-out;

        &__image {
            @include device(mobile) {
                display: none;
            }

            margin-right: 10px;
            cursor: pointer;
        }

        &__btn-compare {
            @include flex;
            @include border-radius(3px);
            transition: none;
            margin-right: 20px;

            &:hover {
                .count { background: $pink; }
            }

            .count {
                background: $darkpink;
                margin-left: 10px;
                display: inline-block;
                @include border-radius(100px);
                @include size(25px);
                @include flex;
            }
        }

        &__btn-clear {
            background: transparent;
            font-size: 32px;

            &:hover {
                i { color: darkred; }
            }
        }
    }
</style>
