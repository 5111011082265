<template>
    <div class="section-header">
        <h2 class="section-header__header">{{ $prismic.asText(header) }}</h2>
        <div
            class="section-header__teaser rich-text"
            v-html="$prismic.asHtml(teaser)"
        />
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: Array,
            required: true,
            default: () => {}
        },
        teaser: {
            type: Array,
            required: false,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .section-header {
        &__header,
        &__teaser {
            @include spacing(margin, 6, bottom);
        }
    }
</style>
