import { Format } from '@swegaming-ab/nuxtjs-helpers';
export default ({ app }, inject) => {

    inject('getMarket', getMarket);
    inject('isSweden', isSweden);
    inject('isNorway', isNorway);
    inject('isFinland', isFinland);
    inject('isGermany', isGermany);
    inject('isCanada', isCanada);

    inject('getRequirements', (model) => {
        let requirements = [];
        if (model.minAge) {
            requirements.push(
                app.$translate( 
                    'core_models_requirements_min_age_string', 
                    'Åldersgräns på {age} år'
                ).replace('{age}', model.minAge)
            );
        }
        else if (! model.minAge) {
            requirements.push(
                app.$translate( 
                    'no_min_age_required', 
                    'Ingen angiven åldersgräns'
                )
            );
        }

        if (model.minimumYearlyIncome) {
            requirements.push(
                app.$translate( 
                    'core_models_requirements_min_yearly_income', 
                    'Minsta årsinkomst på {income}'
                ).replace('{income}', Format.currency(model.minimumYearlyIncome))
            );
        }
        else if (! model.acceptsNoIncome) {
            requirements.push(app.$translate( 
                'core_models_requirements_has_employment', 
                'Har anställning'
            ));
        }

        if (isSweden() || isNorway()) {
            if (! model.acceptsPaymentRemarks) {
                requirements.push(app.$translate( 
                    'core_models_requirements_no_payment_remarks', 
                    'Inga betalningsanmärkningar'
                ));
            }
        }

        if (isSweden()) {
            if (model.isCredit) {
                requirements.push('Inga skulder hos kronofogden');
            }
        }
        
        return requirements;
    });
};

const getMarket = () => {
    return process.env.MARKET;
};

const isSweden = () => {
    return getMarket() === 'sweden';
};
const isNorway = () => {
    return getMarket() === 'norway';
};
const isFinland = () => {
    return getMarket() === 'finland';
};
const isGermany = () => {
    return getMarket() === 'germany';
};
const isCanada = () => {
    return getMarket() === 'canada';
};