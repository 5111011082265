<template>
    <ul class="grid-column">
        <li>{{ columnHeader }}</li>
        <li
            v-for="(iterator, index) in data"
            :key="index"
        >
            <nuxt-link :to="$prismic.linkResolver(iterator.link)">{{ iterator.text }}</nuxt-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        columnHeader: {
            type: String,
            required: true,
            default: String
        },
        data: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
    .grid-column {
        li {
            font-family: $fontText;
            @include font-size(14px, 20px);

            &:first-child {
                font-family: $fontHeader;
                @include font-size(16px,0);
                margin-bottom: 20px;

            }

            a {
                color: $font;

                &:hover { color: $pink; }
            }
        }
    }
</style>
