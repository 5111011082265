<template>
    <a
        :href="getRedirectUrl()"
        target="_blank"
        class="btn bxs"
        :class="[
            'btn-' + background,
            'btn-' + size
        ]"
        @click="$emit('click')"
    >
        {{ text }}
        <i v-if="icon" :class="icon" />
    </a>
</template>
<script>
export default {
    props: {
        background: {
            type: String,
            required: false,
            default: 'transparent'
        },
        size: {
            type: String,
            required: false,
            default: 'md'
        },
        icon: {
            type: String,
            required: false,
            default: String
        },
        link: {
            type: Object,
            required: true,
        },
        text: {
            type: String,
            required: false,
            default: String
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    methods: {
        getRedirectUrl() {
            
            let url = '';
            if (this.link.type === 'lender') {
                url = `/out/${this.link.id}/`;
            }
            else {
                url = `/out/${this.link.uid}/`;
            }

            if (this.placement && this.position && this.position > 0) {
                return `${url}?placement=${this.placement}&position=${this.position}`;
            }

            if (this.placement) {
                return `${url}?placement=${this.placement}`;
            }

            if (this.position && this.position > 0) {
                return `${url}?position=${this.position}`;
            }

            return url;
        }
    }
};
</script>
