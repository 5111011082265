<template>
    <div class="layout-site">
        <Navbar
            :items="$store.state.navigation.body"
            home-link="/"
            fav-icon="/favico.webp"
            :breakpoint="1100"
            :show-icons="true"
            :nav-text="$removeParagraphs($prismic.asHtml($store.state.settings.logo_text))"
        >
            <Search :data="$store.state.cards" />
        </Navbar>

        <Nuxt />

        <client-only>
            <CookieConsent
                :content="$prismic.asHtml($store.state.settings.cookie_notice)"
                :button-text="$store.state.settings.cookie_close_text"
            />
        </client-only>
        <CompareCardsBar />

        <NewsletterSignup />
        <Footer />
    </div>
</template>

<script>
import NewsletterSignup from '@/components/NewsletterSignup.vue';
import { Navbar, CookieConsent } from '@swegaming-ab/vue-components';
import Footer from '@/components/includes/TheFooter';
import Search from '@/components/Search.vue';
import CompareCardsBar from '@/components/compare-cards/CompareCardsBar.vue';
export default {
    components: {
        Search, 
        Navbar, 
        CompareCardsBar, 
        Footer, 
        CookieConsent, 
        NewsletterSignup
    }
}
</script>
