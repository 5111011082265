<template>
    <div class="search-cards">
        <button
            type="button"
            class="add btn"
            @click="open = true"
        />


        <div
            v-if="open"
            class="search-cards__overlay"
            @click="open = false"
        />

        <div
            v-if="open"
            class="search-cards__container"
        >
            <div
                class="container-inner"
                :class="{searched: search.length > 2}"
            >
                <div class="container-inner__input">
                    <input
                        ref="search"
                        v-model="search"
                        type="text"
                        :placeholder="placeholder"
                    >
                    <i
                        class="fas fa-times"
                        @click="open = false, search = ''"
                    />
                </div>

                <ul
                    v-if="search.length > 2"
                    class="container-inner__results"
                >
                    <template v-if="!link">
                        <li
                            v-for="(card, index) in filteredList"
                            :key="index"
                            @click="click(card)"
                        >
                            <PrismicImage
                                :img="card.data.card_image"
                                w="40"
                                h="25"
                            />
                            <span>{{ card.data.title }}</span>
                        </li>
                    </template>

                    <nuxt-link
                        v-else
                        :to="'/kort/' + card.slugs"
                    >
                        <PrismicImage
                            :img="card.data.image"
                            w="40"
                            h="25"
                        />
                        <span>{{ card.data.title }}</span>
                    </nuxt-link>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            required: true,
            default: String
        },
        link: {
            type: Boolean,
            required: true,
            default: Boolean
        }
    },
    data() {
        return {
            search: '',
            filteredList: [],
            open: false
        };
    },
    watch: {
        open() {
            if (this.open) {
                document.body.style.overflow = 'hidden';

                let self = this;
                this.$nextTick(() => {
                    self.$refs.search.focus();
                });
            }
            else {
                document.body.style.overflow = 'visible';
            }
        },
        search() {
            this.filteredList = [];
            Object.keys(this.$store.state.cards).forEach(key => {
                let title = this.$store.state.cards[key].data.title;
                let titleFormatted = title.toLowerCase();
                if (titleFormatted.includes(this.search.toLowerCase())) {
                    this.filteredList.push(this.$store.state.cards[key]);
                }
            });
        }
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode == 27) {
                this.open = false;
                this.search = '';
            }
        });
    },
    methods: {
        click(card) {
            this.$emit('click', card);

            for (let i = 0; i < this.filteredList.length; i++) {
                if (this.filteredList[i].id === card.id) {
                    this.filteredList.splice(i, 1);
                    break;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .search-cards {
        position: relative;

        &__container {
            position: fixed;
            z-index: 2;
            margin-top: -200px;
            width: 500px;
            max-width: calc(100% - 40px);
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);

            .container-inner {
                width: 100%;

                @include spacing(padding,6);
                background: $white;
                @include border-radius(5px);

                &__input {
                    @include flex(between);

                    input {
                        padding: 15px;
                        @include border;
                        box-sizing: border-box;
                        width: calc(100% - 50px);
                        font-size: 16px;
                        @include border-radius(3px);
                        box-shadow: inset rgba(99, 99, 99, 0.15) 0px 2px 8px 0px;
                        outline: none;
                        &:focus {
                            @include border(all, 1px, $pink);
                            outline: 1px $pink;
                            border: 1px solid $pink;
                        }
                    }

                    .fa-times {
                        font-size: 36px;
                        color: $red;
                        cursor: pointer;
                    }
                }

                &__results {
                    position: absolute;
                    left: 0;
                    margin-top: 20px;
                    max-height: 400px;
                    width: 100%;
                    overflow: scroll;
                    @include spacing(padding,4);
                    background: $white;
                    @include border-radius(5px,(bottom-left,bottom-right));
                    @include border(top);

                    li {
                        width: 100%;
                        @include flex(start);
                        @include spacing(padding,2);
                        @include border(bottom);
                        font-family: $fontText;
                        font-size: 14px;
                        cursor: pointer;

                        &:hover { background: $lightpink; }

                        span { margin-left: 20px; }
                    }
                }
            }

            .container-inner.searched {
                @include border-radius(0,(bottom-left,bottom-right));
            }
        }

        &__overlay {
            @include size(100%);
            background: rgba(0,0,0,.7);
            position: fixed;
            left: 0;
            top: 0;
            z-index: 2;
        }

        .add {
            @include size(75px, 47px);
            @include border-radius(3px);
            background: #999;
            border: none;
            position: relative;
            opacity: .7;

            @include device(pad) {
                margin: 0 30px;
            }

            &:hover { opacity: 1; }

            &:before,
            &:after {
                position: absolute;
                content: "";
            }
            &:before {
                width: 100%;
                height: 8px;
                background: #666;
                left: 0;
                top: 12px;
            }
            &:after {
                font-family: "Font Awesome 6 Pro";
                color: #666;
                font-weight: 400;
                font-size: 20px;
                position: absolute;
                bottom: -5px;
                right: -5px;
                content: "\f055";
            }
        }
    }
</style>
