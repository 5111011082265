import Vue from 'vue';

// Local
import ButtonTarget from '@/components/buttons/ButtonTarget.vue';
import HeaderBanner from '@/components/headers/HeaderBanner.vue';
import HeaderSection from '@/components/headers/HeaderSection.vue';

// Package
import { Button, ButtonNuxt, PrismicImage, Section } from '@swegaming-ab/vue-components';

const components = {
    Section,
    Button,
    ButtonTarget,
    ButtonNuxt,
    PrismicImage,
    HeaderBanner,
    HeaderSection
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});


// Registers every component in /slices
// const ComponentContext = require.context('@/components/slices', true, /\.vue$/i, 'lazy');
//
// ComponentContext.keys().forEach((componentFilePath) => {
//     const componentName = componentFilePath.split('/').pop().split('.')[0];
//     Vue.component(componentName, () => ComponentContext(componentFilePath));
// });
