<template>
    <footer class="footer">
        <div class="footer-inner">
            <div class="footer-inner__grid">
                <div class="grid-column">
                    <nuxt-link
                        to="/"
                        class="logo"
                        v-html="$removeParagraphs($prismic.asHtml($store.state.settings.logo_text))"
                    />

                    <MarketSelector />
                </div>

                <Column
                    :column-header="$store.state.settings.footer__first_link_list_header"
                    :data="$store.state.settings.footer__first_link_list"
                />

                <Column
                    :column-header="$store.state.settings.footer__second_link_list_header"
                    :data="$store.state.settings.footer__second_link_list"
                />
            </div>

            <Socials />

            <div
                class="footer-inner__copy"
                v-html="$prismic.asHtml($store.state.settings.footer__disclaimer)"
            />
        </div>
    </footer>
</template>

<script>
import MarketSelector from '../MarketSelector.vue';
import Column from './components/FooterColumn.vue';
import Socials from './components/FooterSocials.vue';
export default {
    components: { MarketSelector, Column, Socials }
};
</script>

<style lang="scss" scoped>
    footer::v-deep {
        @include flex;
        background: $lightblue;
        @include border(top);
        padding: 80px 20px;

        .footer-inner {
            width: 1000px;
            max-width: 100%;
            @include grid(1,20px);


            &__grid {
                width: 100%;
                @include grid(1, 40px);

                @include device(pad) {
                    @include grid(3, 40px);
                }
            }

            &__copy {
                width: 100%;
                color: #999;

                p,
                a {
                    @include font-size(12px);
                    margin: 10px 0;
                }

                a {
                    text-decoration: underline;
                    color: #999;
                    display: block;
                }
            }
        }
        .logo {
            font-family: $fontHeader;
            color: $font;
            @include font-size(32px);
            margin-bottom: 20px;
            display: block;
            text-align: center;
            .pink {
                color: $pink;
            }
            @include device(pad) {
                text-align: left;
            }
        }
    }
</style>
