export default [
    {
        country: 'Sweden',
        domain: 'https://kreditkort.com',
        prismic: 'sv-se',
        hreflang: 'sv-se',
        icon: '/icons/sv.png',
        postTypes: [
            { site: 'kort', prismic: 'credit_card' }
        ]
    },
    {
        country: 'Finland',
        domain: 'https://luottokortit.com',
        prismic: 'fi',
        hreflang: 'fi-fi',
        icon: '/icons/fi.png',
        postTypes: [
            { site: 'kortti', prismic: 'credit_card' }
        ]
    },
    {
        country: 'Norway',
        domain: 'https://kredittkort360.com',
        prismic: 'no',
        hreflang: 'no-no',
        icon: '/icons/no.png',
        postTypes: [
            { site: 'kort', prismic: 'credit_card' }
        ]
    },
    {
        country: 'Germany',
        domain: 'https://kreditkarten360.com',
        prismic: 'de-de',
        hreflang: 'de-de',
        icon: '/icons/de.png',
        postTypes: [
            { site: 'karte', prismic: 'credit_card' }
        ]
    },
    {
        country: 'Canada',
        domain: 'https://creditcards360.ca',
        prismic: 'en-ca',
        hreflang: 'en-ca',
        icon: '/icons/ca.png',
        postTypes: [
            { site: 'card', prismic: 'credit_card' }
        ]
    }
];
