
<template>
    <div v-if="$store.state.settings.newsletter_form_enabled" class="newsletter-form padding-4 rich-text">
        <template v-if="submitted">
            <h5>{{ $store.state.settings.newsletter_form_success_message }}</h5>
        </template>
        <template v-else>
            <h5>{{ $prismic.asText($store.state.settings.newsletter_form_header) }}</h5>
            <p>{{ $store.state.settings.newsletter_form_p }}</p>

            <div
                v-if="emailError"
                class="error"
            >
                <p>{{ $store.state.settings.newsletter_form_email_error_message }}</p>
            </div>
            <div class="form">
                <input v-model="email" type="email" :placeholder="$store.state.settings.newsletter_form_email_placeholder">

                <button
                    class="btn btn-sm btn-green"
                    @click="submit"
                >
                    {{ $store.state.settings.newsletter_form_button_text }}
                </button>
            </div>
            <div class="disclaimer" v-html="$prismic.asHtml($store.state.settings.newsletter_form_disclaimer)" />
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            email: '',
            submitted: false,
            emailError: false
        };
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (! this.email.match(validEmailRegex)) {
                this.emailError = true;
                return;
            }

            this.emailError = false;

            this.$axios
                .post('https://api.broker.compary.com/users', {
                    email: this.email,
                    guide_id: this.$store.state.settings.broker_source_id,
                    tracking: null,
                    redirect_url: null
                })
                .then(() => {
                    this.submitted = true;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
    .newsletter-form {
        text-align: center;
        width: 100%; 
        max-width: 600px;
        margin: 0 auto;
        @include spacing(padding, 8);
    }

    .error {
        width: 100%;
        @include spacing(margin, 4, bottom);
        background: $lightgray;
    }

    .form {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        @include device(pad) {
            grid-template-columns: repeat(1, 2fr 1fr);

        }

        input {
            font-size: 16px;
        }
        button {
            border-radius: 3px;
        }
    }

    .disclaimer ::v-deep p {
        font-size: 12px;
        line-height: 18px;
        color: $mediumgray;
    }
</style>