<template>
    <ul class="market-selector">
        <li
            v-for="(locale, index) in locales"
            :key="index"
            class="market-selector__list-item"
        >
            <a
                :href="locale.domain"
                class="list-item__link"
            >
                <img
                    :src="locale.icon"
                    :alt="locale.country"
                    loading="lazy"
                    class="link-image"
                >

                <span class="link-text">{{ locale.country }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
import locales from '@/config/locales.config.js';
export default {
    data() {
        return {
            locales: locales
        };
    }
};
</script>

<style lang="scss" scoped>
    .market-selector {
        width: auto;
        @include grid(1, 15px);

        &__list-item {
            font-family: $fontText;

            .list-item__link {
                height: 100%;
                font-size: 14px;
                color: $font;
                @include flex;

                @include device(pad) {
                    display: inline-flex;
                }

                &:hover { color: $pink; }

                .link-image {
                    @include size(25px, 17px);
                    @include border-radius(2px);
                    margin-right: 10px;
                }

                .link-text {
                    line-height: 20px;
                }
            }
        }
    }
</style>
