export default ({ app }, inject) => {
    inject('formatDuration', (durationInMonths) => {
        const months = durationInMonths % 12; 
        const years = (durationInMonths - months) / 12;
        let formatted = '';
        if (years > 0) {
            formatted += `${years} ${app.$translate('year_short', 'år')} `;
        }
        if (months > 0 || years === 0) {
            formatted += `${months} ${app.$translate('month_short', 'mån')}`;
        }
        return formatted;
    });
    return app;
};