export default ({ app }, inject) => {
    inject('filterEmptyRelations', filterEmptyRelations);
    inject('filterEmptyElements', filterEmptyElements);
    inject('getRedirectUrl', getRedirectUrl);
    inject('removeParagraphs', removeParagraphs);
    inject('roundHalf', roundHalf);
    inject('truncateString', truncateString);
    
    inject('getApplySecurelyString', (card) => {
        let applySecurelyString = app.$translate('secure_application_at_company', 'Ansök säkert hos {company}');
        return applySecurelyString.replace('{company}', card.data.company_name);
    });
};

const filterEmptyRelations = (relation) => {
    let key = Object.keys(relation)[0];
    return relation[key] && relation[key].id;
};

const filterEmptyElements = (element) => {
    let key = Object.keys(element)[0];
    return element[key];
};

const getRedirectUrl = (card, placement, position) => {

    if (placement && position && position > 0) {
        return `/out/${card.uid}/?placement=${placement}&position=${position}`;
    }

    if (placement) {
        return `/out/${card.uid}/?placement=${placement}`;
    }

    if (position && position > 0) {
        return `/out/${card.uid}/?position=${position}`;
    }

    return `/out/${card.uid}/`;
};

const removeParagraphs = (string) => {
    return string.replace('<p>', '').replace('</p>', '');
};

const roundHalf = (num) => {
    return Math.round(num * 2) / 2;
};

const truncateString = (string, length = 0) => {

    if (length === 0) {
        return string;
    }

    if (string.length > length - 10) {
        return string.substring(0, length) + '...';
    }

    return string;
};
