<template>
    <Section class="banner">
        <div class="grid">
            <div class="grid-container">
                <div>
                    <h1>{{ $store.state.settings.not_found_header || 'Oops!' }}</h1>
                    <p>{{ $store.state.settings.not_found_description || `We could not find the page you're looking for.` }}</p>
                </div>
            </div>

            <div class="grid-container">
                <PrismicImage :img="$store.state.settings.not_found_image" />
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    layout: 'site',
    head() {
        return {
            title: '404'
        };
    }
};
</script>

<style lang="scss" scoped>
    section {
        min-height: 40vh;
        
        .grid {
            @include grid(1, 40px);

            @include device(pad) {
                @include grid(2, 40px);
            }

            &-container {
                &:first-child {
                    @include flex;

                    p {
                        @include font-size(20px, 10px);
                        @include spacing(margin,8,top);
                    }
                }
            }
        }
    }
</style>
