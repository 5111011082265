import getDomain from './get-domain-from-prismic-lang';

export default function (document, withDomain = false) {
    try {

        const documentsMap = require('@/documents.json');
        const documentHelper = documentsMap[document.id];
        const slug = documentHelper.slug;

        if (withDomain) {
            return `${getDomain(document.lang)}${slug}`;
        }

        return slug;
    }
    catch (e) {
        return '/undefined';
    }
}